import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { renderToString } from 'react-dom/server';

import { BsAlignStart as StartFlag } from "react-icons/bs";
import { BsAlignEnd as FinishFlag } from "react-icons/bs";
import { ExclamationCircleFilled } from '@ant-design/icons';

import useAsyncEffect from 'use-async-effect';
import { Typography, Form, Skeleton, Select, Checkbox, Button, Modal, Space, Alert, Menu, Row, Col, Badge, Card, Spin, Tooltip } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime)
import { default as VisTimeline } from 'react-vis-timeline-2'
import { BsCameraFill as CaptureIcon } from 'react-icons/bs'

import { MdSkipNext as NextIcon } from "react-icons/md";
import { MdSkipPrevious as PrevIcon } from "react-icons/md";
import { TfiTarget as Target } from "react-icons/tfi";

import { BsLayoutSplit as Horizontal } from "react-icons/bs";

import { FiSun as Sun  } from "react-icons/fi";
import { FiSunrise as Sunrise } from "react-icons/fi";
import { FiSunset as Sunset } from "react-icons/fi";
import { FiMoon as Moon } from "react-icons/fi";



import { SearchOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { FilterFilled, ClearOutlined, SettingOutlined  } from '@ant-design/icons';
import { BsPlayFill as PlayIcon } from 'react-icons/bs'
import { BsPauseFill as PauseIcon } from 'react-icons/bs'
import { PiSquareSplitVerticalFill as SplitVertical, PiSquareSplitHorizontalFill as SplitHorizontal   } from "react-icons/pi";

import SplitPane, { Pane } from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';


import {
  Route,
  Routes,
  Link,
  Navigate,
  useNavigate 
} from "react-router-dom";
import { useStore } from '../../store';
import PageHeader from '../../components/PageHeader'

import moment from 'moment';
import Table from '../../components/Table';

import sys from '../../system'
import imageUtils from '../../utils/image';
import ImageModel from '../../models/Image';
import SourceModel from '../../models/Source';
import ConfigModel from '../../models/Config'
import ProcessEvent from '../../models/Process_Event';

import { getRangeSunTimes } from './../../utils/SunriseSunsetIO'


import './Timeline.less'
import { set } from 'lodash';


const onBlockSorter = (a, b) => {
  if (a.onBlocks == null) {
    
  }

  if (a.onBlocks != null && a.onBlocks.isAfter(b.onBlocks)) {
    return 1
  }
  if (a.onBlocks == null || a.onBlocks.isBefore(b.onBlocks)) {
    return -1
  }
  return 0
}

const getFlights = async (from, to, stands) => {
  if (stands == null || stands === '' || stands.length == 0) {
    return []
  }

  from = from.subtract(1, 'day').toDate();
  to = to.add(1, 'day').toDate();
  const now = dayjs().subtract(1, 'second').toISOString();

  const response = await fetch(`/api/neurostand/getFlights?from=${from.toISOString()}&to=${to.toISOString()}&stands=${stands}`, {
    headers: {
      Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9',
    }
  })

  const flights = []

  if (response.ok) {
    let json = await response.json()

    json.forEach((f,i) => {
      let style;
      if (f.status === 'ONGOING') {
        style = 'background-color: #ffcc00'
      } else if (f.status === 'SCHEDULED' || f.status === 'ESTIMATED') {
        style = 'background-color: white'
      } else {
        style = undefined
      }

      flights.push({
        group: 'TURNAROUND',
        align:'center',
        type: 'range',
        start: f.TRA,
        end: f.TRD,
        content: `${f.arrivalFlightNr} / ${f.departureFlightNr}`,
        style,
      })
      flights.push({
        group: 'TURNAROUND',
        align:'center',
        type: 'range',
        start: f.onBlocks || f.STA,
        end: f.offBlocks || f.STD,
        style: 'background-color: #F76D64; height: 8px; margin-top: -11px',
      })
    })
  }
  return flights
  
}



const getClosestImage = async (time, sourceId) => {
  if (time == null) {
    return null;
  }
  const images = await sys.orm(`
    select * from (
      (select * from image where source_id = $1 and now > $2 order by now asc limit 1)
      union
      (select * from image where source_id = $1 and now <= $2 order by now desc limit 1)
    ) as t
    `,
    [sourceId, time.toISOString()]
  );

  let image;
  images.forEach(r => {
    r.now = new Date(r.now)
    r.diff = dayjs(r.now).diff(time)
    if (image == null || Math.abs(r.diff) < Math.abs(image.diff)) {
      image = r
    }
  })
  if (image != null) {
    return ImageModel.from(image)
  }
  return null
}

const nextImage = async (time, sourceId) => {
  if (time == null || sourceId == null) {
    return
  }
  const images = await sys.orm(
    'select * from image where source_id = $1 and now > $2 order by now asc limit 1',
    [sourceId, time.toISOString().replace(/\.\d+Z$/,'') + '.999']
  )
  if (images.length > 0) {
    return ImageModel.from(images[0]);
  }
  return null;
}

const prevImage = async (time, sourceId) => {
  if (time == null || sourceId == null) {
    return
  }
  const images = await sys.orm(
    'select * from image where source_id = $1 and now < $2 order by now desc limit 1',
    [sourceId, time.toISOString().replace(/\.\d+Z$/,'') + '.000']
  )
  if (images.length > 0) {
    return ImageModel.from(images[0]);
  }
  return null;
}
  
const Component = forwardRef((props, ref) => {
  
    const elemRef = useRef()
    const timelineRef = useRef()
    const isDragging = useRef(false)
    const internalState = useRef({
      image: null,
      time: props.time,
      //sourceId: props.sourceId,
      source: null,
      simulationId: props.simulationId,
      processId: props.processId,
      range: props.range,
      playing: false,
    });
    const [range, setRange] = useState(null)
    const [image, setImage] = useState(null)
    const [images, setImages] = useState([])
    const [events, setEvents] = useState([])
    const [groups, setGroups] = useState([{
      id: '',
      content: ''
    }])
    const [playing, setPlaying] = useState(false)

    const options = {
      // align: 'center', // alinha o content do range etc
      // clickToUse: true,
      autoResize: true,
      width: '100%',
      stack: false, // false == overlap items
      orientation: 'top',
      verticalScroll: false,
      horizontalScroll: false,
      groupHeightMode: 'fixed',
      order: (a, b) => {
        return a.start - b.start
      },
      margin: {
        axis: 20, // 20
        item: {
          horizontal: 0, // 10
          vertical: 10, // 10
        },
      },
      onMove: async (event, callback) => {
        console.log('onMove', event)
        internalState.current.changingEvent = true
        
        const startId = event.startId
        const endId = event.endId

        const [startEvent, endEvent] = await Promise.all([
          ProcessEvent.get(startId),
          ProcessEvent.get(endId)
        ])

        if (startEvent == null || endEvent == null) {
          Modal.error({
            title: 'Invalid Event',
            content: 'For unknown reason this Event is invalid!'
          })
          await callback(null);
        } else {
          startEvent.now = event.start;
          endEvent.now = event.end;
          startEvent.offset = '00:00';
          endEvent.offset = '00:00';
          const betweenEvent = internalState.current.events?.find(e => {
            if (e.startId != startId && e.endId != endId) {
              if (startEvent.name === e.name) {
                if (startEvent.now.toISOString() <= e.end.toISOString() && endEvent.now.toISOString() >= e.start.toISOString()) {
                  return true;
                }
              }
            }
          })
          if (betweenEvent != null) {
            Modal.error({
              title: 'Illegal Move/Resize',
              content: 'Move/Resize Events to another Event is not supported!'
            })
            await callback(null);
          } else {
            Modal.confirm({
              title: 'Confirmation',
              content: 'Do you want to Move/Resize this Event?',
              icon: <ExclamationCircleFilled />,
              onOk: async () => {
                startEvent.now = event.start;
                endEvent.now = event.end;
                startEvent.offset = '00:00';
                endEvent.offset = '00:00';
                await Promise.all([
                  startEvent.save(),
                  endEvent.save()
                ]);
                await callback(event);
                await update();
              },
              onCancel() {
                return callback(null);
              },
            })
          }
        }

        internalState.current.changingEvent = false
      },
      onRemove: async (event, callback) => {
        console.log('onRemove', event)

        const startId = event.startId
        const endId = event.endId

        if (startId != null && endId != null) {
          Modal.confirm({
            title: 'Confirmation',
            content: 'Do you want to Delete this Event?',
            icon: <ExclamationCircleFilled />,
            onOk: async () => {
              const [startEvent, endEvent] = await Promise.all([
                ProcessEvent.get(startId),
                ProcessEvent.get(endId)
              ])
              startEvent.delete = true,
              endEvent.delete = true,
              await Promise.all([
                startEvent.save(),
                endEvent.save()
              ]);
              await callback(event);
              await update();
            },
            onCancel() {
              return callback(null);
            },
          })
        }
        
      },
      zoomMax: 5 * 52 * 5 * 24 * 60 * 60 * 1000, // 5 anos
      zoomMin: 5 * 60 * 1000, // 10 minuto
      snap: null,
      rollingMode: {
        follow: false
      },
      showCurrentTime: false,
      format: {
        minorLabels: {
          millisecond:'SSS',
          second:     's',
          minute:     'HH:mm',
          hour:       'HH:mm',
          weekday:    'ddd DD',
          day:        'DD',
          week:       'w',
          month:      'MMM',
          year:       'YYYY'
        },
        majorLabels: {
          millisecond:'HH:mm:ss',
          second:     'HH:mm',
          minute:     'YYYY-MM-DD',
          hour:       'YYYY-MM-DD',
          weekday:    'YYYY-MMM',
          day:        'YYYY-MMM',
          week:       'YYYY-MMM',
          month:      'YYYY',
          year:       ''
        }
      }
    }
  
    const moveToClosestImage = async (time) => {
      const timeline = timelineRef.current.timeline;
      const i = await getClosestImage(time, internalState.current.source?.id)
      if (i != null) {
        if (i.id != image?.id) {
          setImage(i)
          timeline.moveTo(i.now?.toDate())
        }
      } else {
        setImage(null)
      }
    };
    
    const update = async () => {
      if (internalState.current.range == null) {
        return;
      }
  
      const start = dayjs(range.start).subtract(1, 'hour')
      const end = dayjs(range.end).add(1, 'hour')
  
      sys.timers.start('timeline-fetcher', async () => {
        const days = end.diff(start, 'days')
        const hours = end.diff(start, 'hours')
  
        const getImages = async (sourceId, start, end) => {
          let trunc = null;
          if (days >= 360) {
            trunc = 'month'
          } else if (days >= 180) {
            trunc = 'week'
          } else if (days >= 90) {
            trunc = 'day'
          } else if (days >= 30) {
            trunc = 'hour'
          } else if (hours >= 10) {
            trunc = 'hour'        
          } else if (hours >= 3) {
            trunc = 'minute'
          } else {
            trunc = 'second'
          }

          const timestamps = await sys.orm(`
            select 
              min(now) min,
              max(now) max,
              count(*)
            from 
              image 
            where 
              source_id = $sourceId
              and image.now between $start and $end
            group by 
              date_trunc('${trunc}', now) 
            order by 
              date_trunc('${trunc}', now)
          `, { 
              sourceId: sourceId, 
              start: start.toISOString(), 
              end: end.toISOString() 
            }
          );
          const images = []
          for (const ts of timestamps) {
            const min = dayjs(ts.min);
            const max = dayjs(ts.max);
  
            let className = 'background';
            const item = {
              className,
              start: min.toDate(),
              end: max.toDate(),
              type: 'background',
            }
            images.push(item)
          }
          return images;
        }
  
        const getEvents = async (simulationId, processId, from, to) => {
          if (end.diff(start, 'month', true) > 1) {
            return []
          }
          let justFuselage = false;
          if (end.diff(start, 'day', true) > 4) { // n mostra nada se for mais de 1 mese
            justFuselage = true;
          }
          from = from.subtract(1, 'day').toDate();
          to = to.add(1, 'day').toDate();
          const now = new Date();

          const processEvents = await sys.orm(`
            select
              id,
              "offset",
              "group",
              name,
              now,
              status
            from 
              process_event
            where 
              simulation_id ${simulationId == null ? 'is null' : '= $simulationId'}
              and process_id ${processId == null ? 'is null' : '= $processId'}
              and now between $from and $to
              ${justFuselage ? "and name = 'FUSELAGE'" : ""}
            order by
              name,
              now,
              id
          `, {
            simulationId,
            processId,
            from: from, 
            to: to
          });

          /** --------- igual ao codigo do FE --------- */
          const events = []

          processEvents.forEach((event, i) => {
            const previous = processEvents[i-1]

            const eventNow = new Date(event.now)
            const eventNowAdjusted = new Date(eventNow.getTime() + sys.timeToMs(event.offset))
            event.now = eventNowAdjusted
        
            if (previous == null) {
              return;
            }
        
            if (previous.process_id === event.process_id && previous.name === event.name && previous.status === 'START' && event.status === 'END') {
              events.push({
                id: [previous?.id, event?.id].filter(id => id != null).join('-'),
                group: previous.group || previous.name,
                name: previous.name,
                content: previous.name,
                type: 'range',
                start: previous.now,
                end: event.now,
                showTooltips: true,
                startId: previous.id,
                endId: event?.id,
                selectable: true,
                editable: {
                  remove: true,
                  updateTime: true
                }
              });
              return
            }

            if (previous.status === 'START' && (previous.process_id !== event.process_id || previous.name !== event.name || event.status === 'START')) {
              if ((now - previous.now) < 3600_000 * 10) { 
                events.push({
                  group: previous.group || previous.name,
                  name: previous.name,
                  content: previous.name,
                  type: 'range',
                  start: previous.now,
                  end: now,
                  startId: previous.id
                });
                return
              }
            }

          })

          return events;
        }
  
        const getSunTimes = async (lat, lng, start, end) => {
          if (end.diff(start, 'month', true) > 1) { // n mostra nada se for mais de 1 mese
            return []
          }
          const stimes = await getRangeSunTimes(lat, lng, start.subtract(1, 'day'), end.add(1, 'day'))

          const ranges = []
     
          let prev = null;
          const style = 'background-color: white; border-width: 1px 1px 1px 0px';
          stimes.forEach(st => {     
            ranges.push({
              group: 'SUN',
              align: 'center',
              type: 'range',
              content: '🌓',
              start: st.dawn.toDate(),
              end: st.sunrise.toDate(),
              style
            })
            ranges.push({
              group: 'SUN',
              align: 'center',
              type: 'range',
              content: '🌕',
              start: st.sunrise.toDate(),
              end: st.sunset.toDate(),
              style
            })
            ranges.push({
              group: 'SUN',
              align: 'center',
              type: 'range',
              content: '🌗',
              start: st.sunset.toDate(),
              end: st.dusk.toDate(),
              style
            })
            if (prev != null) {
              ranges.push({
                group: 'SUN',
                align: 'center',
                type: 'range',
                content: '🌑',
                start: prev.dusk.toDate(),
                end: st.dawn.toDate(),
                style
              })
            }
            prev = st;
          })

          return ranges;
        }

        const [images, events, sunTimes, flights] = await Promise.all([
          getImages(internalState.current.source?.id, start, end),
          getEvents(internalState.current.simulationId, internalState.current.processId, start, end),
          getSunTimes(38.780690047826546, -9.13406167608831, start, end),
          getFlights(start, end, internalState.current.source?.config?.stand)
        ])



        const groupConfigs = {
          'SUN': { order: 0 },
          'TURNAROUND': { order: 1 },
          'FUSELAGE':  { order: 2 },
          'WARNING': { order: 500 }
        }
        events.forEach(e => {
          const group = e.group;
          const groupConfig = groupConfigs[group]
          if (groupConfig == null) {
            groupConfigs[group] = { id: group, content: group, order: Object.keys(groupConfigs).length }
          }
        });
        
        setImages(images)
        internalState.current.events =  [...events, ...sunTimes, ...flights]
        setEvents(internalState.current.events)
        setGroups(Object.entries(groupConfigs).map(([id, config]) => ({
          id,
          ...config,
          //style: "display: none",
          subgroupStack: false,
          nestedGroups: false,
        })))
        moveToClosestImage(internalState.current.time)
      }, 500, 1)
    };

    useImperativeHandle(ref, () => ({
      fit: () => {
        setTimeout(() => {
          const timeline = timelineRef.current.timeline;
          const height = elemRef.current.parentElement.clientHeight;
          options.height = height + 'px';
          timeline.setOptions(options);
        }, 1)
      },
      update,
    }));
    
  


    useAsyncEffect(async () => {
      const timeline = timelineRef.current.timeline;
      timeline.addReplaceCustomTime = function (time, id, title = null, editable = false) {
        // console.log('addReplaceCustomTime', time, id, title, editable)
        if (time == null) {
          try {
            this.removeCustomTime(id)
            return;
          } catch (e) {}
        }
        try {
          if (this.getCustomTime(id) != null) {
            this.setCustomTime(time, id)
            if (title != null) {
              this.setCustomTimeMarker(title, id, editable)
            }
          }
        } catch (e) {
          this.addCustomTime(time, id)
          if (title != null) {
            this.setCustomTimeMarker(title, id, editable)
          }
        }
      }
      setRange({
        start: dayjs(timelineRef.current.timeline.range.start),
        end: dayjs(timelineRef.current.timeline.range.end)
      })
      ref.current.fit()
    }, []);

    useEffect(() => {      
      if (internalState.current.image?.id == image?.id && internalState.current.playing === playing) {
        return;
      }
      internalState.current.image = image;
      internalState.current.playing = playing;
      if (typeof props.onChangeImage === 'function') {
        props.onChangeImage(image)
      }

      if (internalState.current.playing === true) {
        setTimeout(async () => {
          const timeline = timelineRef.current.timeline;
          const time = image?.now;
          const i = await nextImage(time, internalState.current.source?.id)
          if (internalState.current.playing === true) {
            if (i != null) {
              setImage(i)
              timeline.moveTo(i.now?.toDate())
            }
          }
        }, 1000)
      }
    }, [image, playing])




    useAsyncEffect(async () => {
      if (internalState.current.time?.toISOString() == props.time?.toISOString()) {
        return;
      }
      internalState.current.time = props.time;
      moveToClosestImage(props.time)
    }, [props.time]);
    
    useAsyncEffect(async () => {
      internalState.current.range = range;
      update();
    }, [range]);

    useAsyncEffect(async () => {
      if (internalState.current.source?.id == props.sourceId) {
        return;
      }
      //internalState.current.sourceId = props.sourceId
      internalState.current.source = await SourceModel.get(props.sourceId)
      update()
    }, [props.sourceId])
    useAsyncEffect(async () => {
      if (internalState.current.processId == props.processId) {
        return;
      }
      internalState.current.processId = props.processId;
      update()
    }, [props.processId])
    useAsyncEffect(async () => {
      if (internalState.current.simulationId == props.simulationId) {
        return;
      }
      internalState.current.simulationId = props.simulationId;
      update()
    }, [props.simulationId])
  
    useAsyncEffect(async () => { 
      const timeline = timelineRef.current.timeline;
      timeline.addReplaceCustomTime(image?.now?.toDate(), 'current')
      internalState.current.time = image?.now
      internalState.current.image = image
    }, [image])
    
    useEffect(() => {
      const timeline = timelineRef.current.timeline;
      const items = []
      if (images != null){
        items.push(...images)
      }
      if (events != null){
        items.push(...events)
      }
      timeline.setGroups(groups);
      timeline.setItems(items)
    }, [images, events, groups])
  
    
  
  
  
  
    const onTimelineClick = async (ev) => {
      const event = internalState?.current?.events?.find(e => ev.item === e.id)
      if (event != null) {
        console.log(`select * from process_event where id in (${[event.startId, event.endId].filter(e => e != null).map(e => "'" + e + "'")}) order by now desc;`)
      }
      console.log('event', ev)

      if (internalState.current.changingEvent === true) {
        // n faz nada
      } else if (isDragging.current == true) {
        isDragging.current = false;
      } else if (ev.what == null || ev.what === 'background' || (ev.what === 'custom-time' && ev.customTime == 'current')) {
        setPlaying(false)
        moveToClosestImage(ev.time)
      }
    }
    const onTimelineChange = (ev) => {
      // console.log('onTimelineChange', ev);
      if (ev?.event?.type === "panmove") {
        isDragging.current = true
      }
      if (ev.start != null && ev.end != null) {
        setRange({
          start: ev.start,
          end: ev.end
        })
      }
    }
  
    return <div
      ref={elemRef}
      >
      <div 
        style={{
          position: 'relative',
          height: '100%',
          width: '100%',
          bottom: 0,
          margin: 0,
        }}
      >
        <VisTimeline
          ref={timelineRef}
          options={options}
          clickHandler={onTimelineClick}
          rangechangeHandler={onTimelineChange}
        />
        <div
          style={{
            position: 'relative',
            paddingRight: 28,
            paddingBottom: 28,
            bottom: '34px',
            left: '10px',
            margin: 0,
          }}
        >
          <Button
            size='small'
            icon={<Target/>}
            disabled={playing}
            style={{paddingTop: 2, marginRight: 10}} 
            onClick={async () => {
              timelineRef.current.timeline.moveTo(image?.now?.toDate())
            }}
          />
          <Button.Group>
            <Button
              size='small'
              icon={<PrevIcon/>}
              style={{paddingTop: 2}} 
              disabled={playing}
              onClick={async () => {
                const timeline = timelineRef.current.timeline;
                const time = image?.now;
                const i = await prevImage(time, internalState.current.source?.id)
                if (i != null) {
                  setImage(i)
                  timeline.moveTo(i.now?.toDate())
                }
              }}
            />
            <Button
              size='small'
              icon={<NextIcon/>}
              style={{paddingTop: 2}} 
              disabled={playing}
              onClick={async () => {
                const timeline = timelineRef.current.timeline;
                const time = image?.now;
                const i = await nextImage(time, internalState.current.source?.id)
                if (i != null) {
                  setImage(i)
                  timeline.moveTo(i.now?.toDate())
                }
              }}
            />
          </Button.Group>
          <Button
            size='small'
            icon={playing ? <PauseIcon/> : <PlayIcon/>}
            type={playing ? 'primary' : 'default'}
            style={{paddingTop: 2, marginLeft: 10}} 
            onClick={() => {
              setPlaying(!internalState.current.playing);
            }}
          />
        </div>
  
      </div>
    </div>
  })


  Component.displayName = 'Timeline'
  export default Component;