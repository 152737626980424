import sys, { type } from '../system';
const { Table, Uuid, Text, Date, Upper, Number, Relation, Json, DateTime } = type;

export default Table({
    name: 'ProcessEvent',
    table: 'process_event',
    columns: {
        process_run_id: Uuid(),
        node_id: Uuid(),
        simulation_id: Uuid(),
        process_id: Uuid(),
        created_at: DateTime(),
        name: Text(),
        offset: Text(),
        now: DateTime(),
    }
})